<template>
  <section>
    <validation-observer ref="rules">
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Business Profile') }}</b-card-title>
        <div class="ll-required">* {{ $t('Required Fields') }}</div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="3">
            <b-form-group
              label=""
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
              <label class="ll-boldText">{{ $t('Code') }}</label>
              <b-form-input
                id="name"
                v-model="info.name"
                :disabled="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('Company Name')"
              label-for="name"
            >
              <b-form-input
                v-model="info.displayName"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label=""
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Company Type"
                rules="required"
              >
              <label class="ll-boldText">{{$t('Company Type')}}</label>
              <!-- <v-select
                v-if="isCan"
                :options="typeList"
                label="codeDescription"
                @input="selType"
                v-model="type"
              >
              </v-select> -->
              <b-form-input
                v-model="info.orgType"
                :disabled="true"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-form-input
                v-if="!isCan"
                v-model="type"
                :readonly="!isCan"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('Sub Type')"
              label-for="name"
            >
              <v-select
                v-if="isCan"
                :options="subtypeList"
                label="value"
                @input="selSubtype"
                v-model="info.subType">
              </v-select>
              <b-form-input
                v-if="!isCan"
                v-model="info.subType"
                :readonly="!isCan"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              label=""
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
              <label class="ll-boldText">{{ $t('Email') }}</label>
              <b-form-input
                id="email"
                v-model="info.orgEmail"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
            <!-- <b-form-group
              label="Creation Time"
              label-for="name"
            >
              <flat-pickr
                value=""
                class="form-control ll-pickr"
                :config="{ enableTime: false,dateFormat: 'Y-m-d H:i:ss'}"
              />
            </b-form-group> -->
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('Address')"
              label-for="name"
            >
              <b-form-input
                v-model="info.address"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('Country')"
              label-for="name"
            >
              <b-form-input
                v-model="info.country"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('Country code')"
              label-for="name"
            >
              <b-form-input
                v-model="info.countryCode"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              :label="$t('Phone')"
              label-for="name"
            >
              <b-form-input
                v-model="info.orgPhone"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('SP vendor')"
              label-for="sp"
            >
              <v-select
                v-if="isCan"
                :options="spList"
                label="agent"
                @input="selsp"
                v-model="sp"
                multiple
              ></v-select>
              <b-form-input
                v-if="!isCan"
                v-model="sp"
                :readonly="!isCan"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label=""
              label-for="sk"
            >
              <validation-provider
                #default="{ errors }"
                name="KE storer code"
                rules="required"
              >
                <label class="ll-boldText">{{ $t('KE storer code') }}</label>
                <b-form-input
                  v-model="info.keAccountNumber"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('Status Webhook URL')"
              label-for="name"
            >
              <b-form-input
                v-model="info.statusUrl"
                type="url"
              />
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="false">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
              <b-form-input
                id="email"
                v-model="info.orgEmail"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col md="3">
             <b-form-group
              :label="$t('Display label column')"
              label-for="name"
            >
              <v-select
                :options="labelList"
                label="value"
                @input="displayLabel"
                v-model="info.displayLabel">
              </v-select>
            </b-form-group>
          </b-col>
        </b-row> -->
        <b-row>
          <b-col md="3">
            <b-form-group
              :label="$t('Sales Name')"
              label-for="name"
            >
              <b-form-input
                v-model="info.salesName"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- <b-tabs
      pills
      class="ll-tabs"
    >
      <b-tab active>
        <template #title>
          <span class="d-none d-sm-inline">{{ $t('Merchant') }}</span>
        </template> -->
        <b-card no-body id="userList">
        <b-card-header>
          <b-card-title>{{ $t('User List') }}</b-card-title>
        </b-card-header>
        <b-card-body>
        <div class="d-flex justify-content-end mb-1">
          <b-button variant="info" class="mr-1"
            v-b-modal.add-user
          >
            {{ $t('Create User') }}
          </b-button>
          <b-button variant="info" :to="{ name: 'role-list' }">
            Manage Role
          </b-button>
        </div>
        <b-table
          class="position-relative ll-table"
          :items="rows"
          :fields="columns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
          :striped="true"
          :hover="true"
          :sticky-header="true"
          @row-clicked="onUserDetail"
        >
          <template #cell(isEnabled)="row">
            <b-badge
              :variant="row.value ? 'light-info' : 'light-secondary'"
              class="ll-badge">
              <span>{{ row.value ? 'Active' : 'Inactive' }}</span>
            </b-badge>
          </template>
          <template #cell(accountType)="row">
            <span>{{ row.value==='UA' ? 'User Account' : 'System Account' }}</span>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-button
              variant="flat-secondary"
              class="btn-icon rounded-circle ll-act"
              @click="onUserDetail(data.item)"
            >
              <feather-icon icon="EditIcon"/>
            </b-button>
          </template>
        </b-table>
        <!-- show pages -->
        <div class="d-flex justify-content-between flex-wrap ll-p">
          <div class="d-flex align-items-center mb-0 mt-1 mr-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="size"
              :options="pageOptions"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> of {{ totalSize }} entries </span>
          </div>
          <div>
            <b-pagination
              v-model="page"
              :total-rows="totalSize"
              :per-page="size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
        </b-card-body>
        </b-card>
    <b-card no-body >
      <b-card-header>
        <b-card-title>{{ $t('Merchant') }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <div class="searchBox">
          <vxe-button status="primary" @click="insertEvent()">{{ $t('Add') }}</vxe-button>
        <vxe-form :data="formData" @submit="findList">
          <vxe-form-item title="" field="name" :item-render="{}">
            <template #default="{ data }">
              <vxe-input v-model="data.name" :placeholder="$t('Merchant name')"></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item>
            <template #default>
              <vxe-button type="submit" status="primary">{{ $t('Search') }}</vxe-button>
              <vxe-button type="reset">{{ $t('Reset') }}</vxe-button>
            </template>
          </vxe-form-item>
        </vxe-form>
        </div>
        <vxe-table
          border
          show-overflow
          keep-source
          ref="xTable"
          max-height="400"
          :data="items"
          :edit-rules="validRules"
          @edit-closed="editClosedEvent"
          :edit-config="{trigger: 'click', mode: 'row', showStatus: true}"
          empty-text="No matching records found"
        >
          <!-- <vxe-table-column type="checkbox" width="60"></vxe-table-column> -->
          <vxe-table-column field="merchantCode" :title="$t('Merchant code')" ></vxe-table-column>
          <vxe-table-column field="userName" :title="$t('Merchant name')"  >
            <!-- <template #default="{ row }">
              <validation-provider
                #default="{ errors }"
                name="Merchant name"
                rules="required"
              >
                <b-form-input v-model="row.userName"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </template> -->
          </vxe-table-column>
          <vxe-table-column field="createAt" :title="$t('Creation Time')"  >
          </vxe-table-column>
          <vxe-table-column field="email" :title="$t('Email')"
          >
            <template #default="{ row }">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  v-model="row.email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </template>
          </vxe-table-column>
          <vxe-table-column
            field="userAccount" :title="$t('Enable user account')">
            <template #default="{ row }">
              <b-form-checkbox
                v-model="row.userAccount"
                :class="row.userAccount ? 'custom-control-info': 'custom-control-secondary'"
              >
              </b-form-checkbox>
            </template>
          </vxe-table-column>
          <vxe-table-column field="isEnabled" :title="$t('Active')" >
            <template #default="{ row }">
              <b-form-checkbox
                v-model="row.isEnabled"
                :class="row.isEnabled ? 'custom-control-info': 'custom-control-secondary'"
              >
              </b-form-checkbox>
            </template>
          </vxe-table-column>
          <vxe-table-column :title="$t('Action')" width="100" show-overflow>
          <template #default="{ row }" >
            <vxe-button type="text" icon="" @click="removeEvent(row)">
              <feather-icon icon="Trash2Icon" />
            </vxe-button>
          </template>
        </vxe-table-column>
        </vxe-table>
        <!-- show pages -->
        <div class="d-flex justify-content-between flex-wrap ll-p mt-1">
          <div class="d-flex align-items-center mb-0 mt-1 mr-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="size"
              :options="pageOptions"
              class="mx-1"
              @input="handlePageChange1"
            />
            <span class="text-nowrap"> of {{ totalSize1 }} entries </span>
          </div>
          <div>
            <b-pagination
              v-model="page"
              :total-rows="totalSize1"
              :per-page="size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="handleChangePage1"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <!-- WMS Integration -->
    <wms v-if="isShowwms" @wmsInfo="wmsInfo" :fullfillmentInfoList="fullfillmentInfoList"></wms>

    <div class="ll-subBtn" v-if="!isCan1">
      <b-button
        variant="info"
        @click.prevent="validationForm"
      >
        {{ $t('Update') }}
      </b-button>
    </div>
    </validation-observer>
    <!-- Add User -->
    <b-modal
      id="add-user"
      centered
      :title="$t('Create User')"
      size="xl"
      v-model="isAdd"
      hide-footer
    >
      <create-user :info="info" @isCreateUser="isCreateUser"></create-user>
    </b-modal>
    <!-- Edit User -->
    <b-modal
      id="edit-user"
      ref="edit-user"
      centered
      title=""
      size="xl"
      v-model="isEdit"
      hide-footer
    >
      <user-detail :userId="userId"></user-detail>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BCardHeader, BCardTitle, BFormGroup, BFormInput, BButton,
  BFormCheckbox, BFormRadio, BBadge, BTab, BTabs, BPagination, BTable, BFormSelect,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import createUser from './createUser.vue'
import userDetail from './userDetail.vue'
import wms from './wms.vue'

export default {
  components: {
    wms,
    createUser,
    userDetail,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BTable,
    vSelect,
    flatPickr,
    BButton,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BBadge,
    BCardActions,
    BTab,
    BTabs,
    BPagination,
    BTable,
    BFormSelect,
    BModal,
  },
  directives: {},
  data() {
    return{
      required,
      email,
      typeList: [],
      type: '',
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null, // 当前页码
      size: 15, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      columns: [
        { key: 'username', label: this.$t('Username'), sortable: true },
        // { key: 'fullName', label: 'Full name', sortable: true },
        { key: 'accountType', label: this.$t('Account Type'), sortable: true },
        { key: 'email', label: this.$t('Email'), sortable: true },
        { key: 'role', label: this.$t('Role'), sortable: true },
        { key: 'organizationName', label: this.$t('Organization'), sortable: true },
        { key: 'createdAt', label: this.$t('Creation Date'), sortable: true },
        { key: 'isEnabled', label: this.$t('Status'), sortable: true },
        { key: 'action', label: this.$t('Action'), class: 'll-action' },
      ],
      rows: [],
      searchTerm: '',
      roleList: [],
      orgList: [],
      statusList: [
        { id: false, statu: 'Inactive' },
        { id: true, statu: 'Active' },
      ],
      formTime: '',
      toTime: '',
      condition: {
        page: '1',
        size: '50',
        startTime: '',
        endTime: '',
        roleName: '',
        status: '',
        orderMole: 0,
        orderBy: 'userId',
        organizationId: 'info.id',
        userName: '',
      },
      userNList: '',
      filterBg: {
        '--background': '#fff'
      },
      org: null,
      status: null,
      isList: true,
      isDetail: false,
      userId: null,
      accountType: null,
      accountList: [
        { name: 'User Account', key: 'UA' },
        { name: 'System Account', key: 'SA' },
      ],
      isBusy: true,
      info: {
        name: '',
        displayName: null,
        description: null,
        address: null,
        country: null,
        countryCode: null,
        orgType: null,
        orgEmail: null,
        orgPhone: null,
        orgIcon: null,
        merchantList: null,
        subType: null,
        keAccountNumber: null,
        spVendor: null,
        statusUrl: null,
        id: null,
        fullfillmentInfoList: null,
      },
      items: [],
      validRules: {},
      box: '',
      spList: [],
      subtypeList: ['E-Platform'],
      labelList:['Not showing label column','Show KE label','Show Third party label'],
      collapsed: true,
      isShow: false,
      show8: false,
      noPer: [],
      isCan: null,
      isCan1: null,
      formData: { name: '' },
      sp: '',
      arr1: [],
      arr2: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [10, 50, 100],
      page: null,
      size: 15,
      totalSize: null,
      totalPage: null,
      totalSize1: null,
      totalPage1: null,
      columns: [
        { key: 'username', label: this.$t('Username') },
        { key: 'accountType', label: this.$t('Account Type') },
        { key: 'email', label: this.$t('Email') },
        { key: 'role', label: this.$t('Role') },
        { key: 'organizationName', label: this.$t('Organization') },
        { key: 'createdAt', label: this.$t('Creation Date') },
        { key: 'isEnabled', label: this.$t('Status') },
        { key: 'action', label: this.$t('Action'), class: 'll-action' },
      ],
      rows: [],
      condition: {
        page: '1',
        size: '10',
        startTime: '',
        endTime: '',
        roleName: '',
        status: '',
        orderMole: 0,
        orderBy: 'userId',
        organizationId: '',
        userName: '',
      },
      isAdd: false,
      isEdit: false,
      obj: {
        id: null,
        page: 1,
        size: 20,
      },
      userId: null,
      orgCode: null,
      isShowwms: true,
      fullfillmentInfoList: null,
    }
  },
  created() {},
  mounted() {
    this.queryOrgType()
    this.getDetails()
    this.querysp()
    // // console.log(this.$can('Super Admin'))
    this.ability()
    this.ability1()
  },
  destroyed() {
  },
  methods: {
    wmsInfo(val) {
      this.info.fullfillmentInfoList = val
    },
    isCreateUser(val) {
      if (val) {
        this.isAdd = false
        this.getUserList()
      }
    },
    getUser(){
      this.getUserList()
    },
    // 查询merhant
    findList() {
      const len = this.items.length
      const arr = []
      for(var i=0;i<len;i++){
        if(this.items[i].userName.indexOf(this.formData.name)!== -1){
          arr.push(this.items[i])
        }
      }
      this.items = arr
    },
    ability() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      const arr = []
      userData.roleDtos.map(i =>{
        arr.push(i.name)
      })
      // console.log(arr)
      const name = 'Super Admin'
      let flag= arr.findIndex(i => i === name)
      if (flag !== -1) {
        this.isCan = true
        this.condition.organizationId = this.$route.params.id
      } else {
        this.isCan = false
        this.condition.organizationId = this.$route.params.id
      }
    },
    ability1() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      const arr = []
      userData.roleDtos.map(i =>{
        arr.push(i.name)
      })
      const name = 'seller'
      let flag= arr.findIndex(i => i === name)
      if (flag !== -1) {
        this.isCan1 = true
        this.condition.organizationId = this.$route.params.id
      } else {
        this.isCan1 = false
        this.condition.organizationId = this.$route.params.id
      }
    },
    cardAction() {
      this.isShow = !this.isShow
    },
    getDetails() {
      this.obj.id = this.$route.params.id
      this.$http.post('admin/settings/organization/getInfoById', this.obj).then(res => {
        // console.log(res.data.data)
        const info = res.data.data
        this.info = info
        this.fullfillmentInfoList = info.fullfillmentInfoList
        // console.log(this.fullfillmentInfoList)
        // this.info.storeCode = info.keAccountNumber
        this.condition.organizationId = info.id
        this.getUserList()
        this.sp = info.spVendor
        const arr = []
        info.spVendor.map(i =>{
          arr.push(i.agent)
        })
        this.info.spVendor = arr
        if (info.merchantList.content.length !== 0) {
          this.items = info.merchantList.content
        } else {
          // this.items= [
          //   { userName: null, email: null, createdAt:null, userAccount: false, isEnabled: false }
          // ]
        }
        this.totalSize1 = info.merchantList.totalSize
        this.totalPage1 = info.merchantList.totalPage
        // this.orgCode = info.orgCode
        this.$emit('orgCode', info.orgCode)
        if (info.orgType == 'FULLFILLMENT_CUSTOMER') {
          this.isShowwms = true
        } else {
          this.isShowwms = false
        }
      })
    },
    editClosedEvent ({ row, column }) {
      // console.log(row, column)
      const $table = this.$refs.xTable
      const field = column.property
      const cellValue = row[field]
      // console.log(row)
    },
    // 插入新一行
    async insertEvent (row) {
      const $table = this.$refs.xTable
      const record = {
        isEnabled: true,
        userAccount: true,
      }
      const { row: newRow } = await $table.insertAt(record, row)
      await $table.setActiveCell(newRow)
    },
    // 校验 merchant
    async validEvent () {
      const $table = this.$refs.xTable
      const errMap = await $table.validate().catch(errMap => errMap)
      if (!errMap && this.items[0].merchantCode !== null) {
        console.log('校验通过！', errMap)
      } else {
        console.log('校验不通过！', errMap)
      }
    },
    editEvent(row) {

    },
    removeEvent (row) {
      this.box = ''
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete .', {
        title: 'Handle',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          this.box = value
          this.$refs.xTable.remove(row)
          if (row.id !== undefined){
            this.$http.delete(`/admin/settings/organization/delete/${row.id}`).then(res=>{
              if (res.data.code === '200') {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Delete Success',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                this.getDetails()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
              }
            })
          }
        }
      })
    },
    querysp() {
      this.$http.get('admin/settings/organization/getDistinctAgent').then(res =>{
        // console.log(res.data.data)
        this.spList = res.data.data
      })
    },
    selsp(d) {
      if (d) {
        const arr = []
        d.map(i =>{
          arr.push(i.agent)
        })
        this.sp = d
        this.info.spVendor = arr
      } else {
        this.info.spVendor = []
      }
    },
    queryOrgType() {
      // this.$http.get('admin/settings/organization/getMasterListByType?dataType=orgType').then(res => {
      //   this.typeList = res.data.data
      // })
      this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'orgType'}}).then(res => {
        this.typeList = res.data.data
      })
    },
    selType(d) {
      // this.type = t.value
      // this.info.orgType = t.value
      this.info.orgType = d ? d.codeName : null
      this.type = d ? d.codeDescription : null
    },
    selActive(d) {
      console.log(d)
    },
    selSubtype(d) {
      this.info.subType = d ? d : null
    },
    updateCustomer() {
      this.info.id = this.$route.params.id
      this.$http.post('admin/settings/organization/updateOrganization', this.info).then(res => {
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update customer Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'customer-list' })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    submit() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.updateCustomer()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
    // 新增
    add(insertRecords) {
      // console.log(insertRecords)
      if(insertRecords.some(i => i.email === null )){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email can not be empty',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      } else {
        const arr = []
        insertRecords.map(i =>{
          const obj = {}
          // obj.id = i.id
          obj.merchantCode = i.merchantCode
          obj.userName = i.userName
          obj.email = i.email
          obj.userAccount = i.userAccount
          obj.isEnabled = i.isEnabled
          obj.createAt = i.createAt
          arr.push(obj)
          this.info.merchantList = arr
        })
        this.submit()
      }
    },
    // 修改
    update(updateRecords) {
      if(updateRecords.some(i => i.email === null )){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email can not be empty',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      } else {
        const arr = []
        updateRecords.map(i =>{
          const obj = {}
          obj.id = i.id
          obj.merchantCode = i.merchantCode
          obj.userName = i.userName
          obj.email = i.email
          obj.userAccount = i.userAccount
          obj.isEnabled = i.isEnabled
          obj.createAt = i.createAt
          arr.push(obj)
          this.info.merchantList = arr
        })
        this.submit()
      }
    },
    // 既有新增又有修改
    addUpdate(insertRecords, updateRecords) {
      if(insertRecords.some(i => i.email === null )){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email can not be empty',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      } else {
        // const arr1 = []
        insertRecords.map(i =>{
          const obj = {}
          // obj.id = i.id
          obj.merchantCode = i.merchantCode
          obj.userName = i.userName
          obj.email = i.email
          obj.userAccount = i.userAccount
          obj.isEnabled = i.isEnabled
          obj.createAt = i.createAt
          this.arr1.push(obj)
        })
      }
      if(updateRecords.some(i => i.email === null )){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email can not be empty',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      } else {
        // const arr2 = []
        updateRecords.map(i =>{
          const obj = {}
          obj.id = i.id
          obj.merchantCode = i.merchantCode
          obj.userName = i.userName
          obj.email = i.email
          obj.userAccount = i.userAccount
          obj.isEnabled = i.isEnabled
          obj.createAt = i.createAt
          this.arr2.push(obj)
        })
      }
      const arr3 = this.arr1.concat(this.arr2)
      this.info.merchantList =arr3
      this.submit()
    },
    validationForm() {
      const $table = this.$refs.xTable
      const insertRecords = $table.getInsertRecords() // 获取新增
      const updateRecords = $table.getUpdateRecords() // 获取修改
      // switch (insertRecords, updateRecords) {
      //   case (insertRecords.length !== 0 && updateRecords.length === 0):
      //     console.log('1')
      //     break
      //   case (insertRecords.length === 0 && updateRecords.length !== 0):
      //     console.log('2')
      //     break
      //   case (insertRecords.length !== 0 && updateRecords.length !== 0):
      //     console.log('3')
      //     break
      //   default:
      //     console.log('4')
      //     break
      // }
      // 只有新增
      if (insertRecords.length !== 0 && updateRecords.length === 0) {
        this.add(insertRecords)
      // 只有修改
      } else if (insertRecords.length === 0 && updateRecords.length !== 0) {
        this.update(updateRecords)
      // 既有新增又有修改
      } else if (insertRecords.length !== 0 && updateRecords.length !== 0) {
        this.addUpdate(insertRecords, updateRecords)
      // 无操作
      } else {
        this.info.merchantList = []
        this.submit()
      }
    },
    getUserList() {
      this.$http.post('/user/search', this.condition).then(res => {
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.isBusy = false
      }).catch(error => console.log(error))
    },
    onUserDetail(item){
      this.$refs['edit-user'].show()
      this.userId = item.userId.toString()
    },
    handleChangePage(page) {
      this.condition.page = page
      this.getUserList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getUserList()
    },
    handleChangePage1(page) {
      this.obj.page = page
      this.getDetails()
    },
    handlePageChange1(active) {
      this.obj.size = active
      this.getDetails()
    },
  },
}
</script>

<style scoped>
.ll-successIcon{
  fill: #28c76f;
  width: 5em;
  height: 5em;
}
.ll-failIcon{
  fill: #ea5455;
  width: 5em;
  height: 5em;
}
.ll-wmsbox{
  height: 300px;
}
.ll-twmsbox{
  text-align: center;
}
.searchBox{
  display: flex;
  justify-content: space-between;
}
.ll-subBtn{
  text-align: right;
  height: 50px;
}
.ll-radio{
  display: inline-block;
  margin-right: 10px;
}
.ll-addbtn{
  font-size: 14px;
  margin-bottom: 10px;
}
.ll-btnText{
  color: #5e5873;
  font-size: 16px;
}
</style>
<style>
.vxe-modal--body{
  height: auto;
  overflow: unset ;
}
.vxe-modal--wrapper.type--alert .vxe-modal--body,
.vxe-modal--wrapper.type--alert .vxe-modal--body .vxe-modal--content,
.vxe-modal--wrapper.type--confirm .vxe-modal--body,
.vxe-modal--wrapper.type--confirm .vxe-modal--body .vxe-modal--content,
.vxe-modal--wrapper.type--modal .vxe-modal--body,
.vxe-modal--wrapper.type--modal .vxe-modal--body
.vxe-modal--content{
  overflow: unset;
}
.vxe-table--render-default .vxe-body--column.col--ellipsis>.vxe-cell, .vxe-table--render-default .vxe-footer--column.col--ellipsis>.vxe-cell, .vxe-table--render-default .vxe-header--column.col--ellipsis>.vxe-cell {
  max-height: 60px;
  padding: 5px;
}
button:focus{
  outline: none;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
</style>