<template>
  <section>
    <div class="ll-topbox">
      <!-- breadcrumb -->
      <div class="breadcrumb-top">
        <b-breadcrumb class="">
          <b-breadcrumb-item :to="{ name: 'dashboard' }">
            <feather-icon
              icon="HomeIcon"
              size="16"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ $t('Company') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item :to="{ name: 'customer-list' }" >
            {{ $t('Company list') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active >
            {{orgCodes}}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <!-- back button -->
      <b-button
        variant="flat-secondary"
        class="ll-cancel"
        :to="{ name: 'customer-list' }"
      >
        <b-img
          :src="require('@/assets/images/imgs/return.png')"
          height="auto"
          class="mr-1"
        />
      </b-button>
    </div>

    <b-card no-body>
      <b-card-body>
        <overview @orgCode="orgCode"></overview>
        <b-tabs
          pills
          class="ll-tabs"
          v-if="false"
        >
          <!-- Tab: Overview -->
          <b-tab active>
            <template #title>
              <span class="d-none d-sm-inline">{{ $t('Overview') }}</span>
            </template>
            <overview ></overview>
          </b-tab>
          <!-- Tab: Merchants -->
          <b-tab>
            <template #title>
              <span class="d-none d-sm-inline">{{ $t('Merchants') }}</span>
            </template>
            <merchants></merchants>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BImg, BTab, BTabs, BCard, BCardBody, BButton,
} from 'bootstrap-vue'
import Overview from './overview1.vue'
import Merchants from './merchants.vue'

export default {
  components: {
    Overview,
    Merchants,
    BBreadcrumb,
    BBreadcrumbItem,
    BImg,
    BTab,
    BTabs,
    BCard,
    BCardBody,
    BButton,
  },
  data() {
    return {
      orgCodes: '',
      obj: {
        id: null,
        page: 1,
        size: 20,
      }
    }
  },
  mounted() {
    // this.getOrgCode()
  },
  methods: {
    orgCode(val) {
      this.orgCodes = val
    },
    getOrgCode() {
      this.obj.id = this.$route.params.id
      this.$http.post('admin/settings/organization/getInfoById', this.obj ).then(res => {
        const info = res.data.data
        this.orgCode = info.orgCode
      })
    }
  },
}
</script>

<style scoped>

</style>