<template>
  <section>
    <!-- content -->
    <b-card-body>
      <b-tabs
        pills
        class="ll-tabs"
      >
        <!-- Tab: Profile -->
        <b-tab active>
          <template #title>
            <span class="d-none d-sm-inline">{{ $t('Profile') }}</span>
          </template>
          <user-profile :uId="uId" />
        </b-tab>
        <!-- Tab: Password -->
        <b-tab>
          <template #title>
            <span class="d-none d-sm-inline" >{{ $t('Password') }}</span>
          </template>
          <user-password :uId="uId" />
        </b-tab>
      </b-tabs>
    </b-card-body>
  </section>
</template>

<script>
import {
  BRow, BCol, BAvatar, BButton, BFormGroup, BFormInput, BCardHeader, BCardTitle,
  BCard, BFormFile, BBreadcrumb, BBreadcrumbItem,
  BCardBody, BBadge, BDropdown, BDropdownItem, BImg, BTab, BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userProfile from './userProfile.vue'
import userPassword from '../../../config-center/user/components/userPassword.vue'

export default {
  components: {
    userProfile,
    userPassword,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BBadge,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BBreadcrumb,
    BBreadcrumbItem,
    vSelect,
    flatPickr,
    BImg,
    BTab,
    BTabs,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
  },
  props: {
    userId: String,
  },
  data() {
    return {
      userInfo: {
        company: '',
        coverImg: '',
        createdAt: '',
        createdBy: '',
        createdName: '',
        departmentName: '',
        email: '',
        fullName: '',
        fullNameLocale: '',
        isEnabled: '',
        isLocked: '',
        lastLoginAt: '',
        mfaMethod: '',
        phone: '',
        phoneAreaCode: '',
        sex: '',
        updatedAt: '',
        updatedBy: '',
        updatedName: '',
        userExpireAt: '',
        userName: '',
      },
      avata: [],
      roleList: [],
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 1,
      organizationId: '',
      companyList: [],
      condition: {
        page: '1',
        size: '9999',
        startTime: '',
        endTime: '',
        roleName: '',
        status: '',
        orderMole: 0,
        orderBy: 'userId',
        organizationId: '',
        userName: '',
      },
      userList: [],
      uId: null,
      windowHeight: document.documentElement.clientHeight,
      cardbodyHei: document.documentElement.clientHeight*0.8-80
    }
  },
  computed: {
    styleVar() {
      return {
        '--cardbodyHei': this.cardbodyHei + 'px'
      }
    },
  },
  watch: {
    windowHeight(val) {
      this.cardbodyHei = val*0.8-80
    }
  },
  created() {
    // this.uId = this.$route.params.userId
    this.uId = this.userId
  },
  mounted() {
    // this.getList()
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        this.windowHeight = window.fullHeight
      })()
    },
    this.ability()
  },
  methods: {
    ability() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      const arr = []
      userData.roleDtos.map(i =>{
        arr.push(i.name)
      })
      // console.log(arr)
      const name = 'LMD Customer'
      let flag= arr.findIndex(i => i === name)
      if (flag !== -1) {
        this.condition.organizationId = userData.orgId
        this.getList()
      } else {
        this.condition.userName = null
        this.getList()
      }
    },
    getList() {
      this.$http.post('/user/search', this.condition).then(res => {
        // console.log('ss', res.data.code)
        const info = res.data.data
        this.userList = info.content
      }).catch(error => console.log(error))
    },
    submit() {
    },
    onUser(userId) {
      // console.log('sss', userId)
      this.uId = userId.toString()
    },
  },
}
</script>
<style scoped>
.ll-rbtn{
  margin-bottom: 10px;
  text-align: center;
}
.ll-rbtn button{
  width: 135px;
}
.ll-cbody{
  overflow-y: auto;
}
.ll-flex{
  justify-content: space-around;
  width: 30%;
}
.justify-content-start{
  margin-bottom: 10px;
}
.ll-tag, .ll-dropdown{
  width: 100%;
  text-align: right;
  margin-top: 15px;
}
.ll-dropdown .dropdown{
  width: 160px;
}
.ll-dropdown .ll-btn{
  width: 130px;
}
.ll-subBtn{
  text-align: right;
  height: 50px;
}
.ll-topbox{
  display: flex;
  justify-content: space-between;
}
.ll-userList{
  cursor: pointer;
}
.ll-userName:hover{
  color: #ed6d01;
}
.ll-cardbodyHei {
  height: var(--cardbodyHei);
}
@media (min-width: 2000px) {
  .ll-cardbodyHei {
    height: 85vh;
  }
}
.active {
  color: #ff9f43;
}
</style>
<style>
[dir] .custom-file {
  height: 34px;
}
/* [dir] .breadcrumb-top {
  margin-bottom: unset;
} */
</style>
